<template>
	<div style="position: relative;">
		<header id="header" class="header-nav">
			<div class="header-size header-top">
				<div class="d-flex align-items-center justify-content-between">
					<div class="logo" @click="tohome()">
						<img src="../assets/img/nav/n-4-1.png" />
					</div>
				</div>
				<div class="logo-not-logged">
					<el-dropdown @command="changeLanguage">
						<div class="language-button">
							<div class="language-icon-2">
								<img src="../assets/img/nav/n-6.png" />
							</div>
							{{ language=='en'?'EN':'简体' }}
							<div class="language-icon-1">
								<img src="../assets/img/nav/n-5.png" />
							</div>
						</div>
						<el-dropdown-menu slot="dropdown">
						   <el-dropdown-item style="text-align: center;"  command="0">简体</el-dropdown-item>
						   <el-dropdown-item style="text-align: center;"  command="1">EN</el-dropdown-item>
						 </el-dropdown-menu>
					</el-dropdown>
					<div class="cloud-space-1" v-if="loginState">
						<el-dropdown @command="changeUrl">
							<div style="cursor: pointer;display: flex;align-items: center;" :class="hasAvatar?'avatar-active':''">	
								<img v-show="!hasAvatar && avatar_update_time==0" class="dropdown-avatar-img" src="../assets/img/login/avatar.png" />
								<img v-show="hasAvatar && avatar_update_time==0" class="dropdown-avatar-img" src="../assets/img/login/avatar2.png" />
								<img v-show="avatar_update_time>0 && uid" class="dropdown-avatar-img" :src="$baseImg+`/bb/${uid}/avatar.png?x-oss-process=image/resize,m_fixed,h_100,w_100&t=${avatar_update_time}`" />
								{{$store.state.globalData.userName}}
								<i  v-show="!hasAvatar" class="bi bi-chevron-down" style="margin-left: 4px;"></i>
								<i  v-show="hasAvatar" class="bi bi-chevron-up" style="margin-left: 4px;"></i>
							</div>
							<el-dropdown-menu slot="dropdown">
							   <el-dropdown-item style="text-align: center;border-bottom: 1px solid #E5E5E5;"  command="0">{{$t('nav.nav0')}}</el-dropdown-item>
							   <el-dropdown-item style="text-align: center;border-bottom: 1px solid #E5E5E5;"  command="1">{{$t('nav.nav1')}}</el-dropdown-item>
							   <el-dropdown-item style="text-align: center;"  command="2">{{$t('nav.nav2')}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<!-- <div class="cloud-space" v-else @click="login()">
						云空间
					</div> -->
				</div>
			</div>
		</header>
		<login ref="login"></login>
	</div>
</template>
<script>
	import {
		getSolutionCategory,
		getSolutionCategorylist,
		getUserInfo
	} from "../../common/api";
	import login from '@/components/login.vue'
	export default {
		components: {
			login
		},
		props: {},
		data() {
			return{
				language: 'zh',
				hasLang:false,
				isLang:false,
				loginState:false,
				uid:'',
				userName:'',
				avatar_update_time:-1,
				hasAvatar:false,
			}
		},
		watch: {
			'$route'(to, from) {
				this.loginState=localStorage.getItem('loginState')?true:false;
				this.getUserInfoData();
			},
			updtaStatus() {
				this.getUserInfoData();
			},
		},
		mounted() {
			let lang = localStorage.getItem('lang') || 'zh';
			let langlocale = (lang === 'en' ? 'en' : 'zh');
			this.language = langlocale;
			this.loginState=localStorage.getItem('loginState')?true:false;
			this.getUserInfoData();
		},
		methods:{
			getUserInfoData(){
				if(this.loginState){
					const params={}
					getUserInfo(params).then((res) => {
						if(res.code==0){
							let percentage=0;
							if(res.data.capacity_info){
								percentage=(res.data.capacity_info.used/(res.data.capacity_info.total/100)).toFixed(0);
							}
							this.$store.commit('updateGlobalData',{
							    userName:res.data.email,
								avatar_update_time:res.data.avatar_update_time,
								spaceInfo:res.data.capacity_info,
								percentage:percentage
							})
							this.userName=res.data.nickname;
							this.uid=res.data.uid;
							this.avatar_update_time=res.data.avatar_update_time;
						}else{
							localStorage.setItem('loginState', '');
							this.$store.commit('updateGlobalData',{
							    userName:'',
								avatar_update_time:'',
								spaceInfo:'',
								percentage:0,
							})
							//this.$message.error(res.msg);
						}
					})
				}
			},
			login(){
				this.$nextTick(()=>{
					this.$refs.login.dialogFormVisible=true;
					this.$refs.login.activeTab=0;
					this.$refs.login.init();
				})
			},
			changeLanguage(index) {
				let lang = "zh";
				if (index == 0) {
					this.language = 'zh';
					lang = "zh";
				} else {
					this.language = 'en';
					lang = "en";
				}
				localStorage.setItem('lang', lang);
				this.$i18n.locale = lang;
				window.location.reload();
			},
			changeUrl(index){
				if (index == 0) {
					this.$router.push({
						path: '/accountSecurity',
						query: {},
					})
				}else if(index == 1){
					this.$router.push({
						path: '/recorderCloud',
						query: {},
					})
				} else {
					localStorage.setItem('loginState', '');
					this.loginState=false;
					this.hasAvatar=false;
				}
			},
			tohome(){
				this.$router.push('/');
			},
		}
	}
</script>

<style lang="scss" scoped>
	
	::v-deep .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
		    background-color: rgba(230, 244, 244, 1);
		    color: rgba(16, 177, 167, 1);
	}
	.header-nav{
		height: 74px;
		display: flex;
		align-items: center;
		padding: 0 360rpx;
		box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
	}
	.header-top{
		width: 100%;
		display: flex;
		justify-content: space-between !important;
		padding: 0 26px;
	}
	.logo{
		width: 50px;
		height: 36px;
		cursor: pointer;
		img{
			width: 50px;
			height: 36px;
		}
	}
	.logo-not-logged{
		display: flex;
		align-items: center;
		.language-button{
			margin-top: 2px;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: #333333;
			line-height: 19px;
			text-align: left;
			font-style: normal;
			text-transform: none;
			cursor: pointer;
			
			display: flex;
			align-items: center;
			.language-icon-2{
				width: 16px;
				height: 16px;
				margin-right: 4px;
				img{
					width: 16px;
					height: 16px;
				}
			}
			.language-icon-1{
				width: 16px;
				height: 16px;
				margin-left: 8px;
				img{
					width: 16px;
					height: 16px;
				}
			}
		}
		.cloud-space-1{
			margin-left: 48px;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: #333333;
			line-height: 16px;
			text-align: left;
			font-style: normal;
			text-transform: none;
			cursor: pointer;
		}
		.cloud-space{
			margin-left: 48px;
			width: 102px;
			height: 32px;
			border-radius: 16px;
			border: 1px solid #999999;
			
			display: flex;
			align-items: center;
			justify-content: center;
			
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: #333333;
			line-height: 16px;
			text-align: left;
			font-style: normal;
			text-transform: none;
			cursor: pointer;
		}
	}
	.dropdown-avatar-img{
		width: 24px;
		height: 24px;
		border-radius: 50%;
		margin-right: 8px;
	}
	.header-h{
		height: 74px;
	}
</style>