<template>
	<div id="app">
		<Header></Header>
	<!-- 	<Header2 v-show="!haslogin"></Header2> -->
		<section class="section">
			<router-view />
		</section>
	</div>
</template>
<script>
	import Header from '@/components/header.vue'
	import Header2 from '@/components/header2.vue'
	export default {
		name: '',
		components: {
			Header,
			Header2
		},
		data() {
			return {
				isActive: false,
				haslogin: true,
			}
		},
		watch: {
		    '$route'(to, from) {
				// 路由变化时的处理逻辑
				// if(to.path=='/index'){
				// 	this.haslogin=true;
				// }else{
				// 	this.haslogin=false;
				// }
		    }
		},
		created() {
			
		},
		mounted() {
			
		},
		methods: {
			
		},
	}
</script>
<style>
	.section{
		height: calc( 100vh - 74px );
	}
</style>