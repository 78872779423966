import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		//redirect: '/index',///:lang?
		component: () => import('../views/index.vue')
	},
	{
		path: '/:lang?/index',
		name: 'index',
		component: () => import('../views/index.vue')
	},
	{
	  path: '/:lang?/register',
	  name: 'Register',
	  component: () => import( '../views/register.vue')
	},
	{
	  path: '/:lang?/recorderCloud',
	  name: 'RecorderCloud',
	  component: () => import( '../views/recorderCloud.vue')
	},
	{
	  path: '/:lang?/recorderDetails',
	  name: 'RecorderDetails',
	  component: () => import( '../views/recorderDetails.vue')
	},
	{
	  path: '/:lang?/accountSecurity',
	  name: 'AccountSecurity',
	  component: () => import( '../views/accountSecurity.vue')
	},
	{
	  path: '/:lang?/personalCenter',
	  name: 'PersonalCenter',
	  component: () => import( '../views/personalCenter.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: '/', // 确保与Nginx配置中的子目录一致
	routes
})

router.beforeEach((to, from, next) => {
	const lang = to.params.lang;
	if (lang) {
		let langlocale = (lang === 'en' ? 'en' : 'zh');
		router.app.$options.i18n.locale = langlocale;
		localStorage.setItem('lang', langlocale);
		next();
	} else {
		next();
	}
});

//解决点击重复的path报错问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
	//console.log("路由信息",to);
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	return VueRouterPush.call(this, to).catch(err => err)
}
export default router