import axios from 'axios';
import { baseUrl } from "../common/baseUrl.js";
import md5 from "js-md5";
import router from '../src/router/index.js';

const service = axios.create({
	timeout: 8000,
	baseURL: baseUrl
});

service.interceptors.request.use(config => {
		return config;
},err => {
	//Message.error("请求超时");
	return Promise.reject(err);
});

service.interceptors.response.use(response => {
	let data='';
	if(response.data.code==9999){
		router.push({
			path: '/index',
		})
		localStorage.setItem('loginState', '');
		return data = response.data;
	}else{
		return data = response.data;
	}
},
async error => {
	if (error.response) {}
});

export const getRequest = (url, params) => {
  let accessToken = '';//getStore("accessToken");
  let lang=localStorage.getItem('lang') || 'zh';
  lang=(lang=='en'?'en_US':'zh_CN');
  let deviceIdStorage=localStorage.getItem('deviceId');
  let  deviceId='';
  if(!deviceIdStorage){
	 deviceId="WEB"+Math.floor(10000000 + Math.random() * 900000);//设备唯一值
	 localStorage.setItem('deviceId', deviceId);
	 deviceIdStorage=deviceId;
  }
  const d=3;
  const l=lang;//l=en_US   zh_CN
  const ts=parseInt(new Date().getTime() / 1000) + '';//new Date().getTime()/1000;//时间戳
  const n=Math.floor(100000 + Math.random() * 900000);//6位随机数
  const f=deviceIdStorage;
  const tk=localStorage.getItem('loginState') || '';//token
  
  let dataObj={};
  dataObj.d=d;
  dataObj.l=l;
  dataObj.ts=ts;
  dataObj.n=n;
  dataObj.f=deviceIdStorage?deviceIdStorage:f;
  dataObj.tk=tk;
  let signMd5 = paramsSort(dataObj);
  let forinstring = "";
  for (let key in signMd5) {
  		forinstring += "&" + key + "=" + encodeURI(signMd5[key]).replace(/\=/g, "%3D");
  }
  forinstring+='&key=Oq7W8cj7HbwqSfGlysxlYstp6Gh';
  forinstring=forinstring.slice(1);
  signMd5=md5(forinstring).toUpperCase()
  signMd5=signMd5.toLocaleLowerCase();
  let data = {
    method: "get",
    url: `${url}`,
    params: params,
    headers: {
      "Content-Type": "application/json",
      //accessToken: accessToken,
	  a:'ear-20240730',
	  c:'BB',
	  os:'Bro',
      d:d,
      l:l,
      ts:ts,
      n:n,
      f:f,
      tk:tk,
      sign:signMd5,
    },
  };
  return service(data);
};

export const postRequest = (url, params,form,headers) => {
	url=decodeURIComponent(url);
  let accessToken = '';//getStore("accessToken");
  let lang=localStorage.getItem('lang') || 'zh';
  lang=(lang=='en'?'en_US':'zh_CN');
  let deviceIdStorage=localStorage.getItem('deviceId');
  let  deviceId='';
  if(!deviceIdStorage){
	 deviceId="WEB"+Math.floor(10000000 + Math.random() * 900000);//设备唯一值
	 localStorage.setItem('deviceId', deviceId);
	 deviceIdStorage=deviceId;
  }
  const d=3;
  const l=lang;//l=en_US   zh_CN
  const ts=parseInt(new Date().getTime() / 1000) + '';//new Date().getTime()/1000;//时间戳
  const n=Math.floor(100000 + Math.random() * 900000);//6位随机数
  const f=deviceIdStorage;
  const tk=localStorage.getItem('loginState') || '';//token
  
  let dataObj={};
  dataObj.d=d;
  dataObj.l=l;
  dataObj.ts=ts;
  dataObj.n=n;
  dataObj.f=deviceIdStorage?deviceIdStorage:f;
  dataObj.tk=tk;
  let signMd5 = paramsSort(dataObj);
  let forinstring = "";
  for (let key in signMd5) {
		forinstring += "&" + key + "=" + encodeURI(signMd5[key]).replace(/\=/g, "%3D");
  }
  forinstring+='&key=Oq7W8cj7HbwqSfGlysxlYstp6Gh';
  forinstring=forinstring.slice(1);
  signMd5=md5(forinstring).toUpperCase()
  signMd5=signMd5.toLocaleLowerCase();
  return service({
    method: "post",
    url: `${url}`,
    data: params,
    headers: {
      "Content-Type": "application/json",
      //accessToken: accessToken,
	  a:form?'Earphone':'ear-20240730',
	  c:'BB',
	  os:'Bro',
	  d:d,
	  l:l,
	  ts:ts,
	  n:n,
	  f:f,
	  tk:tk,
	  sign:signMd5,
      ...headers
    }
  });
};


//参数排序
function paramsSort(o) {
	let sorted = {},
	keys = Object.keys(o);
	keys.sort();
	keys.forEach((key) => {
		sorted[key] = o[key];
	})
	return sorted;
}