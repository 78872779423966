<template>
	<div>
		<el-dialog :visible.sync="dialogFormVisible" width="498px" top="30vh" :lock-scroll="false" :close-on-click-modal="false">
			<div slot="title" class="dialog-header">
				<div class="dialog-header-logo">
					<img src="../assets/img/login/login.png" />
				</div>
				<div class="dialog-header-tab">
					<div class="tab-1" :class="activeTab==0?'tab-1-active':''" @click="changeTab(0)">{{ $t('login.login1') }}</div>
					<div class="tab-1" :class="activeTab==1?'tab-1-active':''" @click="changeTab(1)">{{ $t('login.loginTab3') }}</div>
					<!-- <div class="tab-1" :class="activeTab==1?'tab-1-active':''" @click="changeTab(1)">{{ $t('login.login2') }}</div> -->
				</div>
			</div>
			<el-form :model="form" ref="login">
				<div v-if="activeTab==0">
					<el-form-item :error="erroraccount">
						<el-input v-model="form.account" :placeholder="$t('login.login3')" autocomplete="off" clearable></el-input>
					</el-form-item>
					<el-form-item :error="errorpassword">
						<el-input v-model="form.password" :maxlength="20" :placeholder="$t('login.login4')" autocomplete="off" show-password></el-input>
					</el-form-item>
				</div>
				<div v-if="activeTab==1">
					<el-form-item :error="erroraccount">
						<el-input v-model="form.account" :placeholder="$t('login.login3')" autocomplete="off" clearable>
							<!-- <template slot="prepend">{{areasName}}</template>
							<el-select v-model="value" slot="prepend" :placeholder="$t('login.login6')" @change="changeSelect">
								<el-option style="color: #333333;" :label="item.code" :value="item.code" v-for="(item,index) in options" :key="index">
								    <span style="float: left">{{ language=='zh'?item.text:item.abbr2 }}</span>
								    <span style="float: right; color: #8492a6; font-size: 13px"> {{item.code}}</span>
								</el-option>
							</el-select> -->
						</el-input>
					</el-form-item>
					<el-form-item :error="errorcode">
						<el-input v-model="form.code" @input="handleInput" :maxlength="4" :placeholder="$t('login.login29')" autocomplete="off">
							<div slot="append" class="input-code" @click="getcode()">{{codeText}}</div>
						</el-input>
					</el-form-item>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<div class="dialog-footer-button">
					<el-button type="primary" @click="login()">{{ $t('login.login8') }}</el-button>
				</div>
				<div class="dialog-footer-bottom">
					<div class="dialog-footer-agreement">
						<div class="forgot-password" @click="forgot()">{{ $t('login.login9') }}</div>
						<div class="register" @click="register()">{{ $t('login.login10') }}</div>
					</div>
					<div class="dialog-footer-agreement-tips">
						<span>{{ $t('login.login11') }}</span>
						<span class="active" @click="changeProtocol(0)">《{{ $t('login.login12') }}》</span>
						{{$t('login.login21')}}
						<span class="active" @click="changeProtocol(1)">《{{ $t('login.login13') }}》</span>
					</div>
				</div>
			</div>
		</el-dialog>
		<forgotBox ref="forgot"></forgotBox>
	</div>
</template>

<script>
	import areas from "../../common/areas";
	import { getLogin , getLoginCode , getSendMSM , getforgotPW } from "../../common/api";
	import md5 from "js-md5";
	import forgotBox from '@/components/forgot.vue'
	import { filters } from "../../common/filters.js";
	export default {
		components: {
			forgotBox
		},
		props: {
			// dialogFormVisible:{
			// 	default: false,
			// 	type: Boolean
			// }
		},
		data() {
			return {
				dialogFormVisible:false,
				activeTab:0,
				form: {
					account: '',
					password: '',
					code:'',
				},
				codeText:this.$t('login.login14'),
				areasName:this.$t('login.login15'),
				value: '+86',
				options: areas,
				timer: 60,
				verification: true,
				erroraccount:'',
				errorpassword:'',
				errorcode:'',
				language: 'zh',
				auth_timer:null,
			};
		},
		computed: {
			
		},
		watch: {
			'form.account':{
				handler:function (val,old){
					if(val==''){
						this.erroraccount='';
					}
					var reg=filters.regPwd(1);
					if(reg.test(val)){
						this.erroraccount='';
					}
				}
			},
			'form.password':{
				handler:function (val,old){
					if(val==''){
						this.errorpassword='';
					}
					var reg=filters.regPwd(0);
					if(reg.test(val)){
						this.errorpassword='';
					}
				}
			}
		},
		created() {

		},
		destroyed(){
			this.codeText=this.$t('login.login14');
			clearInterval(this.auth_timer)
		},
		mounted() {
			let lang = localStorage.getItem('lang') || 'zh';
			let langlocale = (lang === 'en' ? 'en' : 'zh');
			this.language = langlocale;
		},
		methods: {
			handleInput(value) {
			  const reg = /^[\d\.]+$/;
			  if (value && !reg.test(value)) {
				this.form.code = value.replace(/[^\d\.]/g, '');
			  }
			},
			init(){
				this.form.account='';
				this.form.password='';
				this.form.code='';
				this.erroraccount='';
				this.errorpassword='';
				this.errorcode='';
			},
			changeTab(index){
				this.activeTab=index;
				this.init();
				this.verification = true;
				this.codeText=this.$t('login.login14');
				clearInterval(this.auth_timer);
			},
			changeProtocol(index){
				let url='';
				if(index==0){
					if(this.language=='zh'){
						url= '/lang/zh_cn/service.html';
					}else{
						url= '/lang/en_us/service.html';
					}
				}else{
					if(this.language=='zh'){
						url= '/lang/zh_cn/privacy.html';
					}else{
						url= '/lang/en_us/privacy.html';
					}
				}
				window.open(url, '_blank');
			},
			close(){},
			login(){
				if(this.activeTab==0){
					const params={
						"account": this.form.account,
						"pwd": md5(this.form.password)
					}
					if(this.form.account==''){
						this.erroraccount=this.$t('login.login16');
						return;
					}else{
						this.erroraccount='';
					}
					if(this.form.password==''){
						this.errorpassword=this.$t('login.login17');
						return;
					}else{
						this.errorpassword='';
					}
					getLogin(params).then((res) => {
						if(res.code==0){
							this.$message({
							   showClose: true,
							   message: this.$t('login.login18'),
							   type: 'success'
							});
							this.init();
							this.dialogFormVisible=false;
							localStorage.setItem('loginState', res.data.token_info.access_token);
							this.$parent.loginState=true;
							this.$parent.getUserInfoData();
							this.$router.push('/');
						}else{
							this.$message.error(res.msg);
						}
					})
				}else{
					if(this.form.account==''){
						this.erroraccount=this.$t('login.login16');
						return;
					}else{
						this.erroraccount='';
					}
					if(this.form.code==''){
						this.errorcode=this.$t('login.login19');
						return;
					}else{
						this.errorcode='';
					}
					const params={
						"account": this.form.account,
						//"pwd": md5(this.form.password),
						"code":this.form.code,
					}
					getLoginCode(params).then((res) => {
						if(res.code==0){
							this.$message({
							   showClose: true,
							   message: this.$t('login.login18'),
							   type: 'success'
							});
							this.init();
							this.dialogFormVisible=false;
							localStorage.setItem('loginState', res.data.token_info.access_token);
							if(this.$parent.headerStatus){
								this.$parent.loginState=true;
								this.$parent.getUserInfoData();
								this.$router.push('/');
							}else{
								this.$router.push('/');
							}
						}else{
							this.$message.error(res.msg);
						}
					})
				}
			},
			forgot(){
				this.$refs.forgot.dialogFormVisible=true;
				this.$refs.forgot.init();
			},
			register(){
				this.dialogFormVisible=false;
				this.$router.push({
					path: "/register",
					query: {},
				})
			},
			changeSelect(e){
				this.options.filter((item)=>{
					if(item.code==e){
						this.areasName=localStorage.getItem('lang')!='zh'?item.abbr2:item.text
					}
				})
				this.value=e;
			},
			getcode(index){
				let params={
					"account": this.form.account,
					"country_code": this.value,
					"sms_type": 2
				};
				if(this.form.account==''){
					this.erroraccount=this.$t('login.login16');
					return;
				}else{
					this.erroraccount='';
				}
				var reg=filters.regPwd(1);
				if(!reg.test(this.form.account)){
					this.erroraccount=this.$t('register.register18');
					return;
				}else{
					this.erroraccount='';
				}
				if(!this.verification){
					return
				}
				getSendMSM(params).then((res) => {
					if(res.code==0){
						this.$message({
						   showClose: true,
						   message: this.$t('login.login20'),
						   type: 'success'
						});
						this.form.code=res.data.code;
						this.verification = false;
						setTimeout(() => {
							this.timer = 60;
							this.codeText=this.timer+'s';
							
							this.countDown();
						}, 1000);
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			countDown() {
				this.auth_timer = setInterval(() => {
					this.timer--;
					this.codeText=this.timer+'s';
					if (this.timer <= 0) {
						this.codeText=this.$t('login.login14');
						this.verification = true;
						clearInterval(this.auth_timer);
					}
				}, 1000);
			},
		},
	}
</script>

<style lang="scss" scoped>
	::v-deep .el-input__inner{
		border: none !important;
	}
	::v-deep .el-input-group__append{
		border: none !important;
	}
	::v-deep .el-form-item__content{
		border: 1px solid #E5E5E5;
		border-radius: 4px;
	}
	::v-deep .el-input__suffix-inner{
		// border-right: 1px solid #E5E5E5;
		// padding-right: 7px;
	}
	
	::v-deep .el-popup-parent--hidden{
		padding-right: 0 !important;
	}
	
	::v-deep .el-dialog__body{
		padding: 30px 44px 16px !important;
	}
	::v-deep .dialog-footer .el-button{
		width: 410px;
		height: 45px;
		background: #10B1A7;
		border-radius: 4px 4px 4px 4px;
		
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 16px;
		text-align: center;
		font-style: normal;
		text-transform: none;
	}
	::v-deep .el-button--primary{
		    border-color: #10B1A7 !important;
	}
	::v-deep .el-input__inner{
		border: 1px solid #E5E5E5;
	}
	::v-deep .el-input__inner:focus {
		border-color: #E5E5E5;
	}
	::v-deep .el-dialog__header{
		padding: 10px 44px 0 !important;
	}
	::v-deep .el-dialog__footer{
		padding: 10px 44px  32px !important;
	}
	::v-deep .el-dialog__headerbtn{
		top: 30px !important;
	}
	::v-deep .el-dialog__headerbtn .el-dialog__close{
		font-size: 24px !important;
		color: #909399 !important;
	}
	
	
	::v-deep  .el-input__inner{
		height: 45px;
		line-height: 45px;
	}
	::v-deep  .el-input-group__prepend{
		width: 150px;
		padding: 0 0 0 16px!important;
		
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #333333;
		line-height: 16px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}
	::v-deep  .el-select{
		width: 90px;
		margin: -10px 0 !important;
	}
	::v-deep  .el-input-group__prepend{
		background: #FFF !important;
		border: none !important;
	}
	::v-deep .el-input-group__append{
		background-color: #FFFFFF;
	}
	
	::v-deep .el-form-item{
		margin-bottom: 34px;
	}
	
	.dialog-header{
		margin-top: 18px;
	}
	.dialog-header-logo{
		width: 52px;
		height: 42px;
		margin-bottom: 24px;
		img{
			width: 52px;
			height: 42px;
		}
	}
	.dialog-header-tab{
		display: flex;
		.tab-1{
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 20px;
			color: #333333;
			line-height: 23px;
			text-align: left;
			font-style: normal;
			text-transform: none;
			margin-right: 48px;
			cursor: pointer;
		}
		.tab-1-active{
			color: #10B1A7;
			position: relative;
		}
		.tab-1-active::before{
			content: "";
			position: absolute;
			left: 20%;
			right: 20%;
			height: 3px;
			bottom: -10px;
			background-color: #10B1A7;
		}
	}
	.dialog-footer{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.dialog-footer-bottom{
			display: flex;
			flex-direction: column;
			margin-top: 12px;
			width: 100%;
			.dialog-footer-button{
				
			}
			.dialog-footer-agreement{
				display: flex;
				justify-content: space-between;
				.forgot-password{
					font-family: Source Han Sans CN, Source Han Sans CN;
					font-weight: 400;
					font-size: 14px;
					color: #999999;
					line-height: 16px;
					text-align: left;
					font-style: normal;
					text-transform: none;
					cursor: pointer;
				}
				.register{
					font-family: Source Han Sans CN, Source Han Sans CN;
					font-weight: 400;
					font-size: 14px;
					color: #10B1A7;
					line-height: 16px;
					text-align: left;
					font-style: normal;
					text-transform: none;
					cursor: pointer;
				}
			}
			.dialog-footer-agreement-tips{
				display: flex;
				justify-content: center;
				margin-top: 24px;
				
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 12px;
				color: #333333;
				line-height: 14px;
				text-align: left;
				font-style: normal;
				text-transform: none;
			}
			.dialog-footer-agreement-tips .active{
				color: #10B1A7;
				cursor: pointer;
			}
		}
	}
	
	.input-code{
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 16px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		cursor: pointer;
		border-left: 1px solid #E5E5E5 !important;
		padding-left: 21px;
	}
	.input-hr{
		height: 30px;
		width: 1px;
		color: #E5E5E5;
	}
	.input-code:hover{
		color: #10B1A7;
	}
	
	
	@media (max-width: 991px) {
		::v-deep .el-dialog {
			width: 100% !important;
		}
		::v-deep .el-dialog__header{
			padding: 10px 25px 0 !important;
		}
		::v-deep .el-dialog__footer{
			padding: 10px 25px  32px !important;
		}
		::v-deep .el-dialog__body{
			padding: 30px 25px !important;
		}
		::v-deep .dialog-footer .el-button{
			width: 325px;
			height: 45px;
			background: #10B1A7;
			border-radius: 4px 4px 4px 4px;
			
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 16px;
			text-align: center;
			font-style: normal;
			text-transform: none;
		}
	}
</style>