import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import GLightbox from 'glightbox';

import 'glightbox/dist/js/glightbox.min.js';
import "@popperjs/core";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'glightbox/dist/css/glightbox.min.css';
import "bootstrap" 

import 'font-awesome/css/font-awesome.min.css';
import './assets/css/reset.css'
import './assets/css/home.css'
import 'boxicons/dist/boxicons.js'
import 'boxicons/css/boxicons.min.css'

import AOS from 'aos';
import 'aos/dist/aos.css';

import VueWaypoint from 'vue-waypoint'

// import 'swiper/swiper-bundle.min.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import i18n from '../i18n/i18n.js';
import { baseUrl } from "../common/baseUrl";
import moment from 'moment';

import AudioPlayer from './components/audio/index.js'
import VueQr from 'vue-qr'

import { ImagePreview } from '@femessage/vant';
import '@femessage/vant/lib/index.css';

//import '../common/rem'
// import 'amfe-flexible';
	
	
// import TimelineSliderVue from 'timeline-slider-vue'
// import 'timeline-slider-vue/lib/timeline-slider-vue.css'
	
AOS.init(
  {
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
    mirror: false
  }
);

Vue.filter('dateFormat', (str, partten = "YYYY-MM-DD") => {
	return moment(parseInt(str+'000')).format(partten);
})
Vue.filter('fileSize', (bytes) => {
	if (bytes === 0) return '0 B';
	var k = 1024, // or 1024
	sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
	i = Math.floor(Math.log(bytes) / Math.log(k));
	if((bytes / Math.pow(k, i))>1000){
		return (bytes / Math.pow(k, i)).toPrecision(4) + ' ' + sizes[i];
	}else{
		return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
	}
	
})
Vue.filter('formatDate', function (value) {
  if (!value) return '';
  const lang=localStorage.getItem('lang') || 'zh'
  value=parseInt(value+'000')
  const date = new Date(value);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  //return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return lang=='zh'?`${month}月${day}日`:`${month}-${day}`;
});

Vue.filter('formatDate2', function (value) {
  if (!value) return '';
  const lang=localStorage.getItem('lang') || 'zh'
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  value=parseInt(value+'000')-(millisecondsInDay*7)
  const date = new Date(value);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  //return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return lang=='zh'?`${month}月${day}日`:`${month}-${day}`;
});

Vue.filter('formatTime', (milliseconds) => {
	//let min = Math.floor(milliseconds /1000);
	let seconds = Math.floor(milliseconds % 60);
	let minutes = Math.floor(milliseconds / 60);
	let hours = Math.floor(minutes / 60);
	
	const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
	return formattedTime;
})

Vue.filter('formatTime2', (value) => {
	if (value === 0 || value < 1000) return `00:00:00`;
	var timestamp = parseInt(value) / 1000; // 毫秒转秒
	// 小时取余数
	const remainder = timestamp % 3600
	// 时、分、秒
	let hour=0;
	let minute=0;
	let second=0;
	if (remainder === 0) { // 整除 小时
	    hour = parseInt(timestamp / 3600);
	} else {
	    hour = parseInt(timestamp / 3600);
	    let remainderMinute = remainder % 60;
	    if (remainderMinute === 0) { // 整除 分钟
	        minute = parseInt(remainder / 60);
	    } else {
	        minute = parseInt(remainder / 60);
	        second = Math.ceil(remainderMinute);
	    }
	}
	let formattedTime = `${hour>=10?hour:'0'+hour}:${minute>=10?minute:'0'+minute}:${second>=10?second:'0'+second}`;
	return formattedTime;
})
Vue.config.productionTip = false
Vue.prototype.$GLightbox = GLightbox;

// if(process.env.NODE_ENV === 'development'){
// 	Vue.prototype.$baseUrl = 'http://192.168.2.114:8299';
// }else{
// 	Vue.prototype.$baseUrl = 'https://c.vormor.cn';
// }
Vue.prototype.$baseImg = 'http://bbzn-sz.oss-cn-shenzhen.aliyuncs.com';
Vue.prototype.$baseUrl = 'http://192.168.2.114:8299';

Vue.use(VueWaypoint);
Vue.use(AudioPlayer);
Vue.use(VueQr);
Vue.use(ElementUI);
Vue.use(ImagePreview);

// Vue.use(TimelineSliderVue)

new Vue({
  router,
  store,
   i18n,
  render: h => h(App)
}).$mount('#app')
