import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		globalData: {
			userName:'',
			avatar_update_time:'',
			spaceInfo:{},
			percentage:0,
		}
	},
	mutations: {
		updateGlobalData(state, payload) {
			state.globalData = {
				...state.globalData,
				...payload
			};
		}
	},
	actions: {
		updateGlobalData({
			commit
		}, payload) {
			commit('updateGlobalData', payload);
		}
	},
	modules: {}
})
